import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteNotificationPolicyError,
  deleteNotificationPolicyLoading,
  getNotificationPolicyById,
} from '../../../redux/selector/notificationSelector';
import ActionModal from '../../../components/modal/ActionModal';
import { deleteNotificationPolicy } from '../../../redux/slicers/notificationsSlicer';

export default function DeleteNotificationPolicyModal({ policyId, modalOpen, closeModal }) {
  const { t } = useTranslation(['settings']);
  const { user } = useAuth0();
  const dispatch = useDispatch();

  const loading = useSelector(deleteNotificationPolicyLoading);
  const error = useSelector(deleteNotificationPolicyError);
  const policy = useSelector(getNotificationPolicyById(policyId));

  const toastTitle = t(`notifications.policiesTable.${error ? 'deleteFailed' : 'deleteSuccess'}`, {
    name: policy?.name,
  });

  return (
    <ActionModal
      id="delete-notification-policy-modal"
      title={t('notifications.policiesTable.delete')}
      onApprove={async () => dispatch(deleteNotificationPolicy({ policyId, email: user.email }))}
      loading={loading}
      open={modalOpen}
      closeModal={closeModal}
      toastSuccessful={!error}
      toastTitle={toastTitle}
    >
      {t('notifications.policiesTable.deleteConfirmation', {
        name: policy?.name,
      })}
    </ActionModal>
  );
}
