export const OR_OPERATOR = '_NOKODOR_';

export const IS = 'is';
export const IS_NOT = 'isNot';
export const IS_NULL = 'isNull';
export const CONTAINS = 'contains';
export const GREATER_THAN = 'greaterThan';
export const LESS_THAN = 'lessThan';
export const AFTER = 'after';
export const BEFORE = 'before';

export const columnsMetadata = {
  severity: {
    operators: [IS, IS_NOT],
  },
  inProduction: {
    operators: [IS],
  },
  dataAccess: {
    operators: [IS],
  },
  businessContextName: {
    operators: [IS, CONTAINS],
  },
  status: {
    operators: [IS, IS_NOT],
  },
  connectedStatus: {
    operators: [IS, IS_NOT],
  },
  lastChange: {
    operators: [BEFORE, AFTER],
  },
  lastModified: {
    operators: [BEFORE, AFTER],
  },
  creationTime: {
    operators: [BEFORE, AFTER],
  },
  createdAt: {
    operators: [BEFORE, AFTER],
  },
  insights: {
    operators: [GREATER_THAN, LESS_THAN],
  },
  insightsCount: {
    operators: [GREATER_THAN, LESS_THAN],
  },
  activeInsights: {
    operators: [GREATER_THAN, LESS_THAN],
  },
  applicationCount: {
    operators: [GREATER_THAN, LESS_THAN],
  },
  applicationsCount: {
    operators: [GREATER_THAN, LESS_THAN],
  },
  connectionsCount: {
    operators: [GREATER_THAN, LESS_THAN],
  },
  timeOfDetection: {
    operators: [BEFORE, AFTER],
  },
  resource: {
    operators: [CONTAINS],
  },
  resourceName: {
    operators: [CONTAINS],
  },
  applicationName: {
    operators: [CONTAINS],
  },
  platform: {
    operators: [IS, IS_NOT],
  },
  platformName: {
    operators: [IS, IS_NOT],
  },
  type: {
    operators: [IS, IS_NOT],
  },
  insightType: {
    operators: [IS, IS_NOT],
  },
  shareType: {
    operators: [IS, IS_NOT],
  },
  connectorName: {
    operators: [IS, IS_NOT],
  },
  'Insight Name': {
    operators: [IS, IS_NOT],
  },
  owner: {
    operators: [CONTAINS],
  },
  ownerName: {
    operators: [CONTAINS],
  },
  resolved: {
    operators: [IS],
  },
  guest: {
    operators: [IS],
  },
  scope: {
    operators: [IS, IS_NOT],
  },
  scopeSubType: {
    operators: [IS, IS_NOT],
  },
  name: {
    operators: [CONTAINS],
  },
  environments: {
    operators: [IS],
  },
  environmentNames: {
    operators: [IS],
  },
  tags: {
    operators: [IS],
  },
  closedAt: {
    operators: [BEFORE, AFTER],
  },
  publisher: {
    operators: [IS, IS_NOT],
  },
  documentation: {
    operators: [IS],
  },
  firstSeen: {
    operators: [BEFORE, AFTER],
  },
  connectorId: {
    operators: [IS],
  },
  share: {
    operators: [IS],
  },
  createTime: {
    operators: [BEFORE, AFTER],
  },
  updateTime: {
    operators: [BEFORE, AFTER],
  },
  modifyTime: {
    operators: [BEFORE, AFTER],
  },
  environment: {
    operators: [IS],
  },
  production: {
    operators: [IS],
  },
  apps: {
    operators: [GREATER_THAN, LESS_THAN],
  },
  score: {
    operators: [IS, GREATER_THAN, LESS_THAN],
  },
  aggregationName: {
    operators: [IS],
  },
  userName: {
    operators: [CONTAINS],
  },
  email: {
    operators: [CONTAINS],
  },
  lastActivity: {
    operators: [BEFORE, AFTER],
  },
  lastLoginTime: {
    operators: [BEFORE, AFTER],
  },
  active: {
    operators: [IS],
  },
  resourceActive: {
    operators: [IS],
  },
  public: {
    operators: [IS],
  },
  sharedWithOrg: {
    operators: [IS],
  },
  dependency: {
    operators: [IS, IS_NOT],
  },
  workspace: {
    operators: [CONTAINS],
  },
};
