import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import DataSources from './DataSources';
import RelatedFlows from './RelatedFlows';
import AppInstances from './AppInstances';
import FlowRequests from './FlowRequests';
import ApplicationData from './ApplicationData';
import ApplicationRules from './ApplicationRules';
import DependenciesTable from './DependenciesTable';
import ApplicationHistory from './ApplicationHistory';
import ApplicationInsights from './ApplicationInsights';
import ApplicationUsersTable from './ApplicationUsersTable';
import {
  fetchApplicationConnections,
  fetchApplicationData,
  fetchApplicationRelatedFlows,
  fetchApplicationUsers,
  fetchUsedByApplications,
} from '../../../redux/slicers/applicationSlicer';
import {
  getApplicationConnections,
  getApplicationData,
  getApplicationRelatedFlows,
  getApplicationUsers,
  getUsedByApplications,
  isAllApplicationDataFinishedLoading,
  isSingleApplicationLoading,
} from '../../../redux/selector/applictionSelector';
import { getEnvironments } from '../../../redux/selector/environmentsSelector';
import { fetchApplicationInsights } from '../../../redux/slicers/insightSlicer';
import { getApplicationInsights } from '../../../redux/selector/insightsSelector';
import { StyledSwitch } from '../../../components/StyledSwitch';
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';
import CollapseCard from '../../../components/collapse-card/CollapseCard';
import EntitiesFlow from '../../../components/entities-flow/EntitiesFlow';
import { ENTITIES, USER_TYPES } from '../../../consts/Entities';
import { UI_PATH } from '../../../consts/platforms';
import { checkIsFlow } from '../../../services/utils';
import { getInsightsObj } from '../../../components/entities-flow/utils/flowUtils';

import './application-page.scss';

export default function SingleApplicationPage() {
  const [showClosed, setShowClosed] = useState(false);
  const { appId } = useParams();
  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();
  const application = useSelector(getApplicationData(appId));
  const isLoading = useSelector(isSingleApplicationLoading) || !application;
  const isFinishedLoading = useSelector(isAllApplicationDataFinishedLoading);
  const environments = useSelector(getEnvironments);
  const insights = useSelector(getApplicationInsights(appId));

  const usedByApps = useSelector(getUsedByApplications(appId));
  const relatedFlows = useSelector(getApplicationRelatedFlows(appId));
  const appUsers = useSelector(getApplicationUsers(appId));
  const nonOwnerUsers = appUsers?.filter((user) => user.id !== application?.ownerId);

  const appConnections = useSelector(getApplicationConnections(appId));

  const { t } = useTranslation(['common', 'single-application']);

  const showDependencies = application?.account.platformName === UI_PATH;

  const activeInsights = (insights || []).filter((insight) => insight.active);
  const activeAppInstances = application?.appInstances?.filter((appInstance) => appInstance.active) || [];
  const isFlow = checkIsFlow(application);

  const fromParam = searchParams.get('from') || '';
  const linksBreadcrumbs = `${fromParam},${application?.name}`;

  const graphData = {
    root: {
      ...application,
      entityType: isFlow ? ENTITIES.FLOWS : ENTITIES.APPLICATIONS,
      insights: getInsightsObj(activeInsights),
    },
    flows: relatedFlows,
    applications: usedByApps,
    users: appUsers.filter(({ type }) => type !== USER_TYPES.GROUP),
    groups: appUsers.filter(({ type }) => type === USER_TYPES.GROUP),
    connections: appConnections,
  };

  useEffect(() => {
    setShowClosed(application?.status === 'DELETED');
  }, [application?.status]);

  useEffect(() => {
    (async function fetchData() {
      if (!application) {
        dispatch(fetchApplicationData(appId));
      } else {
        dispatch(fetchApplicationInsights(application));
        if (isFlow && !usedByApps) {
          // if it's a flow, fetch which apps are using it (when usedByApps is not yet fetched)
          dispatch(fetchUsedByApplications(appId));
        } else if (!isFlow && !relatedFlows) {
          // if it's an app, fetch which flows are related to it (when relatedFlows is not yet fetched)
          dispatch(fetchApplicationRelatedFlows(appId));
        }
      }
    })();
  }, [application]);

  useEffect(() => {
    (async function fetchData() {
      dispatch(fetchApplicationUsers(appId));
      dispatch(fetchApplicationConnections(appId));
    })();
  }, [appId]);

  return (
    <div className="application-page">
      <Breadcrumbs current={application?.name} />
      <CollapseCard
        title={t('applicationData.title', { ns: 'single-application' })}
        initialOpen
        isLoading={isLoading}
        collapseEnabled={false}
      >
        <ApplicationData application={application} isFlow={isFlow} linksBreadcrumbs={linksBreadcrumbs} />
        <AppInstances
          isFlow={isFlow}
          allAppInstances={activeAppInstances}
          isLoading={isLoading}
          platform={application?.account.platformName}
          appId={appId}
          accountId={application?.accountId}
        />
      </CollapseCard>

      <CollapseCard title={t('general.entitiesGraph')} initialOpen isLoading={!isFinishedLoading}>
        <EntitiesFlow data={graphData} isFinishLoading={isFinishedLoading} />
      </CollapseCard>

      <div
        className={classnames('collapse-card-container', {
          'with-content': !!activeInsights.length,
        })}
      >
        <CollapseCard
          title={t(isFlow ? 'flowInsights' : 'applicationInsights', { ns: 'single-application' })}
          totalElementsCount={(showClosed ? insights : activeInsights).length}
          initialOpen={false}
          isLoading={isLoading}
        >
          <div className="show-closed-toggle">
            <span>{t('insights.showClosed')}</span>
            <StyledSwitch checked={showClosed} onChange={() => setShowClosed(!showClosed)} />
          </div>
          <ApplicationInsights insights={showClosed ? insights : activeInsights} linksBreadcrumbs={linksBreadcrumbs} />
        </CollapseCard>
      </div>

      <div className="card-view-table-container ">
        {isFlow && (
          <CollapseCard
            title={t('usedBy', { ns: 'single-application' })}
            initialOpen={false}
            isLoading={isLoading}
            totalElementsCount={usedByApps?.length}
          >
            <FlowRequests linksBreadcrumbs={linksBreadcrumbs} applications={usedByApps} isFlow />
          </CollapseCard>
        )}

        {!!(!isFlow && relatedFlows?.length) && (
          <CollapseCard
            title={t('applications.flows')}
            initialOpen={false}
            isLoading={isLoading}
            totalElementsCount={relatedFlows.length}
            margin={false}
          >
            <RelatedFlows linksBreadcrumbs={linksBreadcrumbs} flows={relatedFlows} />
          </CollapseCard>
        )}
      </div>

      <div className="card-view-table-container">
        <div className="collapse-card-container">
          <ApplicationUsersTable isLoading={isLoading} users={nonOwnerUsers || []} />
        </div>
      </div>

      <div className="card-view-table-container">
        <div className="collapse-card-container">
          <DataSources connections={appConnections} isLoading={isLoading} linksBreadcrumbs={linksBreadcrumbs} />
        </div>
      </div>
      <ApplicationRules insights={insights} isLoading={isLoading} />
      {showDependencies && <DependenciesTable appId={appId} />}
      <ApplicationHistory application={application} environments={environments} isLoading={isLoading} />
    </div>
  );
}
