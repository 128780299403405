import React from 'react';
import { camelCase } from 'lodash';
import { mapSeverityFromCode } from '../../../services/MapperUtils';
import ColoredBox from '../../../components/box/ColoredBox';
import SeverityBox from '../../../components/box/SeverityBox';

const Operator = Object.freeze({
  GREATER_THAN_EQUALS: '≥',
  GREATER_THAN: '>',
  LESS_THAN_EQUALS: '≤',
  LESS_THAN: '<',
  EQUALS: '=',
  NOT_EQUALS: '≠',
  OR: 'OR',
  AND: 'AND',
});

function getValue({ conditionKey, value }) {
  switch (conditionKey) {
    case 'SEVERITY':
      return <SeverityBox severity={mapSeverityFromCode(value)} />;
    default:
      return camelCase(value.toLowerCase());
  }
}

export default function NotificationPolicyFilters({ filters }) {
  if (!filters.length) return <div>All Insights</div>;

  const renderFilter = (filter) => (
    <div key={`${filter.conditionKey}_${filter.operator}`} className="notification-policy-filter">
      <span>{camelCase(filter.conditionKey.toLowerCase())}</span>
      <ColoredBox color="gray" text={Operator[filter.operator]} isFilled hasBorder={false} />
      <span>{getValue(filter)}</span>
    </div>
  );

  return (
    <div className="notification-policy-filters">
      {filters.map((filter, index) => (
        <React.Fragment key={`${filter.conditionKey}_${filter.operator}`}>
          {index > 0 && <ColoredBox color="gray" text={Operator.AND} isFilled hasBorder={false} />}
          {renderFilter(filter, index)}
        </React.Fragment>
      ))}
    </div>
  );
}
