import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import NotificationPolicyCreateDrawer from './NotificationPolicyCreateDrawer';
import BlurredDrawer from '../../../components/blurred-drawer/BlurredDrawer';
import { mapDate } from '../../../services/MapperUtils';
import { StyledSwitch } from '../../../components/StyledSwitch';
import TableEmptyContent from '../../../components/table/TableEmptyContent';
import emptyApplicationsImg from '../../inventory/empty_applications.svg';
import Table from '../../../components/table/Table';
import DeleteNotificationPolicyModal from './DeleteNotificationPolicyModal';
import NotificationPolicyFilters from './NotificationPolicyFilters';
import ToggleNotificationPolicyModal from './ToggleNotificationPolicyModal';
import { NotificationChannelName } from './NotificationChannelName';

const ModalType = Object.freeze({
  TOGGLE: 'toggle',
  DELETE: 'delete',
});

function EmptyPolicy() {
  const { t } = useTranslation(['settings']);
  return (
    <div className="notification-policy-empty">
      <div className="notification-policy-empty-title">{t('notifications.policiesTable.empty')}</div>
      <div className="notification-policy-empty-text">{t('notifications.policiesTable.emptyDescription')}</div>
    </div>
  );
}

export default function NotificationPolicyTable({ policies, isLoading }) {
  const { t } = useTranslation(['settings']);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedPolicyId, setSelectedPolicyId] = useState(null);
  const [modalType, setModalType] = useState(null);

  const columns = useMemo(
    () => [
      {
        Header: ' ',
        accessor: 'isActive',
        width: '5%',
        Cell: ({ value, cell }) => (
          <StyledSwitch
            checked={value}
            onClick={() => {
              setSelectedPolicyId(cell.row.original.id);
              setModalType(ModalType.TOGGLE);
            }}
          />
        ),
      },
      {
        Header: t('notifications.policiesTable.columns.name'),
        accessor: 'name',
        width: '15%',
      },
      {
        Header: t('notifications.policiesTable.columns.creatorName'),
        accessor: 'creatorName',
        width: '10%',
      },
      {
        Header: t('notifications.policiesTable.columns.filters'),
        accessor: 'policyConditions',
        width: '30%',
        Cell: ({ value }) => <NotificationPolicyFilters filters={value.conditions} />,
      },
      {
        Header: t('notifications.policiesTable.columns.creationTime'),
        accessor: 'dbCreatedAt',
        width: '15%',
        Cell: ({ value }) => (value ? mapDate(value) : '-'),
      },
      {
        Header: t('notifications.policiesTable.columns.associatedChannels'),
        accessor: 'notificationChanelPolicies',
        width: '15%',
        Cell: ({ value }) =>
          (value || [])
            .map((policy) => policy.notificationChannel)
            .map((channel) => <NotificationChannelName name={channel.name} type={channel.type} />),
      },
      {
        Header: t('notifications.policiesTable.columns.reminder'),
        accessor: 'reminderInterval',
        width: '5%',
        Cell: ({ value }) => !!value && <CheckIcon className="reminder-check" />,
      },
      {
        Header: ' ',
        accessor: 'id',
        Cell: ({ value }) => (
          <div className="notification-actions">
            <EditIcon
              className="notification-actions-edit"
              onClick={() => {
                setSelectedPolicyId(value);
                setDrawerOpen(true);
              }}
            />
            <DeleteOutlineOutlinedIcon
              className="notification-actions-delete"
              onClick={() => {
                setSelectedPolicyId(value);
                setModalType(ModalType.DELETE);
              }}
            />
          </div>
        ),
      },
    ],
    [],
  );

  const hasPolicies = policies.length || isLoading;
  const sortBy = [{ id: 'name', desc: false }];

  return (
    <div className="notifications-settings-table">
      <div className="add-new-item">
        <div className="add-new-item-title">{t('notifications.policies')}</div>
        <div className="add-new-item-button" onClick={() => setDrawerOpen(true)}>
          + {t('notifications.policiesTable.addNew')}
        </div>
      </div>
      <DeleteNotificationPolicyModal
        modalOpen={modalType === ModalType.DELETE}
        closeModal={() => setModalType(null)}
        policyId={selectedPolicyId}
      />
      <ToggleNotificationPolicyModal
        modalOpen={modalType === ModalType.TOGGLE}
        closeModal={() => setModalType(null)}
        policyId={selectedPolicyId}
      />
      <BlurredDrawer open={drawerOpen} setOpen={setDrawerOpen} onClose={() => setSelectedPolicyId(null)}>
        <NotificationPolicyCreateDrawer policyId={selectedPolicyId} />
      </BlurredDrawer>
      {hasPolicies ? (
        <Table
          columns={columns}
          data={policies}
          totalCount={policies.length}
          options={{ showFilter: false, showPagination: false, showSort: true, usePaginationSearchParam: false }}
          type="notification-policies"
          shouldScrollOnPagination={false}
          sortBy={sortBy}
          initialFilters={[]}
          customFilters={[]}
          isRowDisabled={() => false}
          getElementId={(app) => app.id}
          isLoading={isLoading}
          classNames="card-view-table"
          backendPagination={false}
          pageSize={100}
          emptyState={<TableEmptyContent img={emptyApplicationsImg} mainText={t('notifications.policies.empty')} />}
        />
      ) : (
        <EmptyPolicy />
      )}
    </div>
  );
}
